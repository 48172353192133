import React from "react"
import Layout from "../../components/layout"
import FoodItempage from "../../components/members/foodItems"

const FoodItemspage = () => {
    return (
        <Layout>
            <FoodItempage />
        </Layout>
    )
}

export default FoodItemspage
